@import 'variables.scss';

.primarybtn {
    display: inline-block;
    border: none;
    outline: none;
    font-size:14px;
    line-height: 1;
    color: $color_white;
    font-weight: 600;
    margin: 0;
    padding: 12px 15px;
    min-width: 120px;
    height: auto;
    background: $color_primary;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2);

    &:hover {
        opacity: .9;
        background: $color_primary !important;
        color: $color_white !important;
    }

    &:focus {
        opacity: .9;
        background: $color_primary !important;
        color:$color_white !important;
    }
    &:disabled {
        opacity: .5;
        background: $color_gray !important;
        color:$color_white !important;
        cursor: not-allowed;
    }
}

.secondarybtn {
    display: inline-block;
    border: 1px solid $color_primary;
    outline: none;
    font-size:14px;
    line-height: 1;
    color: $color_primary;
    font-weight: 600;
    margin: 0;
    padding: 12px 15px;
    min-width: 120px;
    height: auto;
    background: $color_white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2);

    &:hover {
        opacity: .9;
        background: $color_white;
        color: $color_primary !important;
        border-color:$color_primary !important;
    }

    &:focus {
        opacity: .9;
        background: $color_white;
        color: $color_primary !important;
        border-color:$color_primary !important;
    }
    &:disabled {
        opacity: .5;
        background: $color_gray;
        color: $color_white !important;
        border-color:$color_gray !important;
        cursor: not-allowed;
    }
}

.ghostbtn {
    display: inline-block;
    border:1px solid $color_secondary;
    outline: none;
    font-size:14px;
    line-height: 1;
    color: $color_secondary;
    font-weight: 600;
    margin: 0;
    padding: 10px 15px;
    min-width: 115px;
    height: auto;
    background:none;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2);
    border-radius:3px;
    &:hover {
        opacity: .9;
        background:none;
        color: $color_secondary !important;
        border:1px solid $color_secondary !important;
        
    }

    &:focus {
        opacity: .9;
        background:none;
        color: $color_secondary !important;
        border:1px solid $color_secondary !important;
      
    }
    &:disabled {
        opacity: .5;
        background: $color_gray;
        color: $color_white !important;
        border-color:$color_gray !important;
        cursor: not-allowed;
    }
}

.dangerbtn {
    display: inline-block;
    border: none;
    outline: none;
    font-size:14px;
    line-height: 1;
    color: $color_white;
    font-weight: 600;
    margin: 0;
    padding: 12px 15px;
    min-width: 120px;
    height: auto;
    background: $color_danger;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2);

    &:hover {
        opacity: .9;
        background: $color_danger;
        color: $color_white !important;
    }

    &:focus {
        opacity: .9;
        background: $color_danger;
        color: $color_white !important;
    }
    &:disabled {
        opacity: .5;
        background: $color_gray;
        color: $color_white !important;
        border-color:$color_gray !important;
        cursor: not-allowed;
    }
}

.linkbtn {
    display: inline-block;
    border: none;
    outline: none;
    font-size:14px;
    line-height: 1;
    color: $color_primary;
    font-weight: 500;
    margin: 0;
    padding:9px 15px;
    height: auto;
    background: none;
    box-shadow:none;

    &:hover {
        opacity: .9;
        background:none;
        color: $color_primary !important;
    }

    &:focus {
        opacity: .9;
        background:none;
        color: $color_primary !important;
    }
    &:disabled {
        opacity: .5;
        background: none;
        color: $color_white !important;
        border-color:$color_gray !important;
        cursor: not-allowed;
    }
}


.mb-0{ margin-bottom:0 !important; }
.ml-16{ margin-left:16px !important; }

.pt-15{ padding-top:15px !important; }
.pb-0{ padding-bottom:0 !important; }
.pb-3{ padding-bottom:3px !important; }

.b-none{ border:none !important; }

.dflex{ display:flex; }
.align-items-center{ align-items:center; }
.justify-content-between{ justify-content:space-between; }
.flex-1{ flex:1; }

.text-align-right{ text-align:right; }